.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 3.3vh;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
}

.articles-subtitle {
	width: 80%;
	display: flex;
	flex-direction: column;
}

.articles-subtitle .blog-btn {
	background: #ffd41a;
	padding: 10px 15px;
	border-radius: 30px;
	color: #27272a;
	font-weight: 500;
	margin-top: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-size: 14px;
	line-height: normal;
	max-width: 230px;
}

.articles-subtitle .blog-btn:hover {
	background: #f3c70a;
	transition: 0.5ms all ease-in-out;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
