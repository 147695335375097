@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-container {
	padding-top: 25px;
}

.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.homepage-title {
	width: 85% !important;
}

.homepage-subtitle {
	width: 85% !important;
}

.highlight-name {
	background: linear-gradient(90deg, #ecd81e, #f8af27f1, #dfdb03);
	/* Define your gradient colors */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 46px;
	/* Optional: Make the highlighted text larger */
	font-weight: bold;
}

.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}

.homepage-image-container {
	width: 370px;
	height: 370px;
}

.homepage-image-container .buttonWrapper {
	margin-top: 30px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.homepage-image-container .buttonWrapper .blog-btn {
	background: #ffd41a;
	padding: 10px 15px;
	border-radius: 30px;
	color: #27272a;
	font-weight: 500;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	line-height: normal;
	min-width: 130px;
	max-width: 230px;
	cursor: pointer;
	border: none;
}

.homepage-image-container .buttonWrapper .blog-btn:hover {
	background: #f3c70a;
	transition: 0.5ms all ease-in-out;
}

.homepage-image-container .buttonWrapper .blog-btn:focus-within,
.homepage-image-container .buttonWrapper .blog-btn:focus {
	border: none;
}

.homepage-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
	transform: rotate(3deg);
}

.homepage-image-wrapper img {
	width: 100%;
}

.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.homepage-social-icon:hover {
	color: var(--primary-color);
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}

.homepage-article {
	padding-bottom: 5px;
}

.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column;
		/* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-articles {
		flex-basis: auto;
	}

	.homepage-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}
}
