.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.bullets-satya {
    margin: 30px 0 0 0;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    max-width: 500px;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out;
}

.bullets-satya:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.bullets-satya span {
    display: block;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
}

.bullets-satya span::after {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #007BFF;
    margin: 12px auto 0;
    border-radius: 4px;
}

.bullets-satya ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.bullets-satya li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    color: #555;
    font-size: 1.15em;
    line-height: 1.6;
    transition: color 0.3s ease, transform 0.3s ease;
}

.bullets-satya li::before {
    content: "✔";
    position: absolute;
    left: 0;
    color: #007BFF;
    font-size: 1.3em;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; /* Hidden initially */
    animation: fadeInBullet 0.5s ease forwards; /* Fade-in animation for the bullet */
}

.bullets-satya li:hover {
    color: #b39802;
    cursor: pointer;
    animation: hoverLift 0.4s ease forwards; 
	box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
	border-radius: 18px;
	padding: 10px 0 10px 20px;
	transition: 0.3s ease, transform 0.3s ease;
}

.bullets-satya li:hover::before {
    color: #0056b3;
    animation: hoverBulletColor 0.4s ease forwards; /* Bullet color change animation */
}

/* Keyframes for the list item lift animation */
@keyframes hoverLift {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-3px); /* Slight lift */
    }
}

/* Keyframes for the bullet fade-in */
@keyframes fadeInBullet {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Keyframes for bullet color change on hover */
@keyframes hoverBulletColor {
    0% {
        color: #007BFF;
    }
    100% {
        color: #0056b3;
    }
}


@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}

	.bullets-satya {
        padding: 15px; /* Adjust padding for smaller screens */
    }
    
    .bullets-satya span {
        font-size: 1.5em; /* Smaller heading font size */
    }

    .bullets-satya li {
        font-size: 1em; /* Smaller list item font size */
    }
}
